<template>
    <div>
    <h1>Bienvenido a su apartado para definir encuestas</h1>
      <b-form @submit="enviarEncuestaFinal" v-if="permisos">

        <b-button @click="visualizarEncuesta" variant="success">Visualizar Encuestas</b-button>
        <div v-if="estadoEncuesta=='En curso'">
          <div v-for="pregunta in formulario.preguntas" :key="pregunta.nombre" class="mb-1" >
            <br>
            <h6>{{pregunta.nombre}}</h6>
            <b-form-select v-if="pregunta.tipo=='Múltiple'" :options="pregunta.opciones" v-model="pregunta.valor" multiple="true" required></b-form-select>
    
          </div>

          <b-button type="submit" variant="primary">Enviar encuesta</b-button>

        </div>

        <b-card class="mt-3" header="Form Data Result">
          <pre class="m-0">{{ formulario }}</pre>
        </b-card>

      </b-form>

    </div>

  </template>
  
  <script>
  import axios from 'axios'
    export default {
      data() {
        return {
          permisos: true,


          estadoEncuesta:"",
          //Encuesta actual
          formulario:{ 
            "preguntas": [ 
            { "nombre": "Seleccione la encuesta que desea activar:", "tipo": "Múltiple", "opciones": [ "Encuesta 1", "Encuesta 2", "Encuesta 3", "Encuesta 4","Encuesta 5"], valor:""},
              { "nombre": "Seleccione sus encuestadores que participan en la aplicación de esta encuesta:", "tipo": "Múltiple", "opciones": [ "Fulano", "Mengano", "Perengano"], valor:"" },
              { "nombre": "Seleccione la encuesta que desea activar:", "tipo": "Múltiple", "opciones": [ "En Preparación", "Activa","Terminada"], valor:"" },
            ] 
          }

        }
      },
      methods: {
        visualizarEncuesta(){
          this.estadoEncuesta = 'En curso';
          ////acceso al microfono
          //acceso al gps
        },
        enviarEncuestaFinal(event) {
          event.preventDefault()
          alert(JSON.stringify(this.formulario));
          /*COLCOAR PARTE PARA MANDAR AL SERVIDOR*/
          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });


          
        },
      }
    }
  </script>